import React, { useState } from "react";
import "../styles/navbar.scss";

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navbarMain">
      <div className="coName">Beyond Seek</div>
      <div className={`sections ${menuOpen ? "showMenu" : ""}`}>
        <div>Stories</div>
        <div>Team</div>
        <div>Careers</div>
        <div>Blogs</div>
        <div>
          <a href="https://oncehub.com/BeyondSeek" target="_blank">
            <button>Talk to us</button>
          </a>
        </div>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default Navbar;
