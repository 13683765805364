import React, { useEffect, useLayoutEffect } from "react";
import "../styles/contact.scss";
import sky from "../assets/img/sky.json";
import Lottie from "lottie-react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Contact: React.FC = () => {
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (window.innerWidth > 768) {
        const contactInner = document.querySelector(".contactinner .wrapper");

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: contactInner,
            start: "top bottom",
            end: "top 40%",
            scrub: true,
          },
        });
        tl.from(contactInner, { y: 300 });
      }
    });

    return () => ctx.revert();
  }, []);
  return (
    <div className="contactouter">
      <div className="backgroundstars">
        <Lottie className="backgroundimg" animationData={sky} loop autoplay />
      </div>
      <div className="contactinner">
        <div className="wrapper">
          <h1>Talk to us</h1>
          <p>Let's discuss your situation</p>
          <a href="https://oncehub.com/BeyondSeek" target="_blank">
            <button>Book a Free Consultation</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
